import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';

import { AccountRegistration, ContactInfo, LoginDetails } from './steps';

export function RegistrationStart({ step }: { step: number }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // State to track which step has been completed
  const [completedStep, setCompletedStep] = useState(
    localStorage.getItem('completedStep') ? Number(localStorage.getItem('completedStep')) : 1,
  );

  useEffect(() => {
    //store step changes in local storage
    const savedCompletedStep = localStorage.getItem('completedStep');
    if (savedCompletedStep) {
      setCompletedStep(Number(savedCompletedStep));
    }
  }, []);

  // Handle going back to the previous step
  const handleGoBack = () => {
    if (isNaN(Number(step)) || Number(step) <= 1 || !step) {
      navigate('/login');
      return;
    }
    navigate(`/registration/${Number(step) - 1}`);
  };

  // Render the appropriate step based on user progress
  const renderStep = () => {
    if (Number(step) > completedStep) {
      // If user tries to access a step they haven't completed, redirect them back to the last completed step
      return <Navigate to={`/registration/${completedStep}`} replace />;
    }

    switch (step) {
      case 1:
        return <AccountRegistration setCompletedStep={setCompletedStep} />;
      case 2:
        return <ContactInfo setCompletedStep={setCompletedStep} />;
      case 3:
        return <LoginDetails />;
      default:
        return <Navigate to='/registration/1' replace />;
    }
  };

  return (
    <Box>
      <Button
        leftIcon={<ChevronLeftIcon fontSize={23} />}
        mb={2}
        px={0}
        variant='accentTertiary'
        size='md'
        onClick={handleGoBack}
      >
        {step === 1 ? t('registrationPage.actions.landingPage') : t('registrationPage.actions.previous')}
      </Button>

      <Text color='text.primary.default'>{t('registrationPage.stepper', { step })}</Text>
      <Routes>
        <Route path=':step' element={renderStep()} />
      </Routes>
    </Box>
  );
}
