/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Card as ChakraCard, CardProps, Flex, Link } from '@chakra-ui/react';
import _ from 'lodash';

import { Status } from '../../interfaces';
import { DataLoadingErrorAlert, EmptyDataAlert } from '../errorAlert';

export interface ICard extends CardProps {
  button?: {
    text: string;
    to: string;
    icon?: any;
    variant?: string;
  };
  loadingStatus?: Status;
  link?: any;
  component?: any;
}

export const Card = ({ button, loadingStatus, link, component, children, ...props }: ICard) => {
  const isLoading = loadingStatus === Status.Loading;
  const isEmptyData = loadingStatus === Status.Idle && _.isEmpty(children);
  const isError = loadingStatus === Status.Failed;
  const { t } = useTranslation();

  return (
    <ChakraCard as='section' maxW='100%' h='100%' w='100%' data-testid='card' {...props}>
      {isError && (
        <Box data-testid='data-loading-error-alert' h='full' w='full'>
          <DataLoadingErrorAlert />
        </Box>
      )}
      {isEmptyData && (
        <Box data-testid='empty-data-alert'>
          <EmptyDataAlert />
        </Box>
      )}
      {!isError && !isEmptyData && children}
      {_.isEmpty(button) || isError ? null : (
        <Flex width={'100%'} justify={'right'} mt='auto' pt={4} opacity={isLoading ? '20%' : '100%'}>
          <Button as={NavLink} to={button.to} size='sm' variant={button.variant || 'accentSecondary'}>
            {button.text}
          </Button>
        </Flex>
      )}
      {_.isEmpty(link) || isError ? null : (
        <Link ml='4' href={link?.href} isExternal alignSelf={'start'}>
          <>
            <QuestionOutlineIcon boxSize={4} mr='1.5' />
            {t(`${link?.key}`)}
          </>
        </Link>
      )}
      {_.isEmpty(component) || isError ? null : component}
    </ChakraCard>
  );
};
