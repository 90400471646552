import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { selectAuthRequestPath } from '@/features/auth/userSlice';
import { selectHeaderStatus } from '@/features/dashboard/slice';
import { userProfileFetchRequest, userProfilesFetchRequest } from '@/features/settings/profile/slice';

import { useFetchDataCheck } from './utils';

export const useFetchUserProfile = () => {
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const canFetchData = useFetchDataCheck('profile', false, false);

  useEffect(() => {
    if (canFetchData) {
      dispatch(userProfileFetchRequest({ authRequestPath }));
    }
  }, [authRequestPath, canFetchData, dispatch]);
};

export const useFetchUserProfiles = () => {
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const headerStatus = useAppSelector(selectHeaderStatus);
  const canFetchData = useFetchDataCheck('profiles', false, false);

  useEffect(() => {
    if (canFetchData) {
      dispatch(userProfilesFetchRequest({ authRequestPath }));
    }
  }, [authRequestPath, canFetchData, dispatch, headerStatus]);
};
