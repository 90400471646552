import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Flex, Spinner } from '@chakra-ui/react';
import { max, min } from 'lodash';

import { useAppSelector } from '@/app/hooks';
import { useFetchCompetitors } from '@/common/hooks/fetchDashboardData';
import { Status, TransactionTypes, Verticals } from '@/common/interfaces';
import { selectCompetitorsStatus, selectSelectedCompetitorTypes } from '@/features/explore/locations/slice';
import { ICompetitor, ISitePerformance, MapVisualizations } from '@/features/explore/locations/types';
import colors from '@/theme/foundations/colors';

import { ITableData, Table } from '../table/Table';

import { COMPETITOR_COLORS, LEGEND_COLORS, MAP_STYLES, ZOOM_LEVEL } from './constants';
import { MapLegend } from './MapLegend';
import { mapStyles } from './styles';
import { MapProps } from './types';
import { buildModalTableRows, calculateScale, formatColor, getColorForPercentage } from './utils';

const LocationsMap = (props: MapProps) => {
  const competitorsStatus = useAppSelector(selectCompetitorsStatus);
  const selectedCompetitorTypes = useAppSelector(selectSelectedCompetitorTypes);

  const mapContainerRef = useRef<HTMLDivElement>();
  const markersRef = useRef<google.maps.Marker[]>([]);
  const exclusivityZoneMarkersRef = useRef<google.maps.Circle[]>([]);
  const competitorsMarkersRef = useRef<google.maps.Marker[]>([]);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [mapIsReady, setMapIsReady] = useState<boolean>(false);
  const [currentZoom, setCurrentZoom] = useState<number>(4);
  const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [selectedMarkerData, setSelectedMarkerData] = useState<{
    site: ISitePerformance;
    marker: google.maps.Marker;
  } | null>(null);

  const showExclusivityZones =
    props.visualization === MapVisualizations.Competitors && selectedCompetitorTypes.includes('EXCLUSIVITY_ZONE');

  useFetchCompetitors({
    zoomLevel: currentZoom,
    visibleBounds: currentBounds,
    mapVisualization: props.visualization,
  });

  const mapOptions = useMemo(() => {
    const defaultMapOptions: google.maps.MapOptions = {
      center: new google.maps.LatLng(39.8333, -98.5833),
      zoom: 4,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
      },
      gestureHandling: 'cooperative',
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: mapStyles,
    };
    return { ...defaultMapOptions, ...props.mapOptions };
  }, [props.mapOptions]);

  useEffect(() => {
    if (mapContainerRef.current && !map) {
      setMap(new window.google.maps.Map(mapContainerRef.current, mapOptions));

      infoWindowRef.current = new google.maps.InfoWindow({ content: '' });

      setMapIsReady(true);
    }

    return () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }
    };
  }, [map, mapOptions]);

  useEffect(() => {
    if (showExclusivityZones && map) {
      exclusivityZoneMarkersRef.current.forEach((marker) => marker.setMap(map));
    }

    if (!showExclusivityZones) {
      exclusivityZoneMarkersRef.current.forEach((marker) => marker.setMap(null));
    }
  }, [showExclusivityZones, map]);

  const renderTooltip = useCallback(
    (site: ISitePerformance): string => {
      const tableData: ITableData = {
        title: `${site.name} - ${site.address}`,
        rows: buildModalTableRows(props.vertical, site, props.transactionType),
      };

      return renderToString(<Table data={tableData} />);
    },
    [props.vertical, props.transactionType],
  );

  const [minRevenue, maxRevenue] = useMemo((): Array<number> => {
    if (!props.stats) {
      return [0, 0];
    }

    const values = props.stats.sites.map((site: ISitePerformance) => {
      if (props.vertical !== Verticals.Fuel || props.transactionType === TransactionTypes.CStore) {
        return site.totalRevenue;
      }

      if (props.vertical === Verticals.Fuel) {
        return Number(site.totalGallonsBought);
      }

      return 0;
    });

    if (!values.length) {
      return [0, 0];
    }

    return [Number(min(values)), Number(max(values))];
  }, [props.stats, props.transactionType, props.vertical]);

  const renderMarkers = useCallback(
    (sites: ISitePerformance[]) => {
      if (!map) {
        return;
      }

      const storeCount = sites.length;
      let value = storeCount;

      sites.forEach((site: ISitePerformance) => {
        const defaultIcon = {
          path: google.maps.SymbolPath.CIRCLE,
        };

        const icon = defaultIcon;

        if (props.vertical === Verticals.Fuel && props.visualization === MapVisualizations.Competitors) {
          const competitorIcon = {
            scale: 3,
            fillColor: colors.blue['1200'],
            strokeColor: colors.blue['1200'],
            fillOpacity: 1,
          };

          Object.assign(icon, competitorIcon);
        } else {
          let hasZeroTransactions = false;

          if (props.vertical !== Verticals.Fuel || props.transactionType === TransactionTypes.CStore) {
            hasZeroTransactions = site.totalRevenue === 0;
          }

          if (props.vertical === Verticals.Fuel && props.transactionType !== TransactionTypes.CStore) {
            hasZeroTransactions = site.totalGallonsBought === 0;
          }

          const revenuePercent = hasZeroTransactions ? 0 : (value / storeCount) * 100;
          value = value - 1; //next site has less totalRevenue, so value/rank goes down by 1
          const color = getColorForPercentage(revenuePercent);
          const scale = calculateScale(revenuePercent);
          const heatmapIcon = {
            scale: scale,
            fillColor: color,
            strokeColor: color,
            fillOpacity: 0.6,
            strokeWeight: 1,
            strokeOpacity: 1,
          };

          Object.assign(icon, heatmapIcon);
        }

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(site.latitude, site.longitude),
          map,
          title: site.name,
          icon,
          zIndex: 2,
        });

        if (props.vertical === Verticals.Fuel && props.visualization === MapVisualizations.Competitors) {
          const exclusivityZoneMarker = new google.maps.Circle({
            strokeColor: colors.blue['200'],
            strokeOpacity: 0.35,
            strokeWeight: 1,
            fillColor: colors.blue['200'],
            fillOpacity: 0.35,
            map,
            center: new google.maps.LatLng(site.latitude, site.longitude),
            radius: site.exclusivityZone,
            zIndex: 1,
          });

          exclusivityZoneMarkersRef.current.push(exclusivityZoneMarker);
        }

        marker.addListener('click', () => {
          setSelectedMarkerData({ site, marker });
        });

        markersRef.current.push(marker);
      });
    },
    [map, props.vertical, props.visualization, props.transactionType],
  );

  const renderCompetitors = useCallback(
    (competitors: ICompetitor[], zoomLevel: number) => {
      if (competitorsMarkersRef.current.length) {
        competitorsMarkersRef.current.forEach((marker) => marker.setMap(null));
        competitorsMarkersRef.current = [];
      }

      if (
        !map ||
        props.visualization !== MapVisualizations.Competitors ||
        props.vertical !== Verticals.Fuel ||
        zoomLevel < 10
      ) {
        return;
      }

      competitors.forEach((competitor: ICompetitor) => {
        const competitorIcon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 2.5,
          fillColor: COMPETITOR_COLORS[competitor.type],
          strokeColor: COMPETITOR_COLORS[competitor.type],
          fillOpacity: 1,
        };

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(competitor.geolocation.latitude, competitor.geolocation.longitude),
          map,
          title: competitor.site_brand,
          icon: competitorIcon,
          zIndex: 0,
        });

        competitorsMarkersRef.current.push(marker);
      });
    },
    [map, props.visualization, props.vertical],
  );

  const cleanMarkers = useCallback(
    (visibleMarkers: ISitePerformance[]) => {
      markersRef.current = markersRef.current.filter((marker) => {
        const isExistingMarker = visibleMarkers.some((site) => {
          const markerLatLng = new google.maps.LatLng(site.latitude, site.longitude);

          return markerLatLng.equals(marker.getPosition() as google.maps.LatLng);
        });

        if (!isExistingMarker) {
          marker.setMap(null);
        }

        return isExistingMarker;
      });

      exclusivityZoneMarkersRef.current = exclusivityZoneMarkersRef.current.filter((marker) => {
        const isExistingMarker = visibleMarkers.some((site) => {
          const markerLatLng = new google.maps.LatLng(site.latitude, site.longitude);

          const markerInfo = marker.getCenter();

          if (markerInfo) {
            return markerInfo.equals(markerLatLng);
          }

          return false;
        });

        if (!isExistingMarker) {
          marker.setMap(null);
        }

        return isExistingMarker;
      });
    },
    [markersRef, exclusivityZoneMarkersRef],
  );

  const loadVisibleMarkers = useCallback(
    (visibleBounds: google.maps.LatLngBounds, sites: ISitePerformance[]) => {
      if (!sites.length || !map || !props.visualization) {
        return;
      }

      const visibleMarkers = sites.filter((site) => {
        const markerLatLng = new google.maps.LatLng(site.latitude, site.longitude);

        return visibleBounds.contains(markerLatLng);
      });

      if (!visibleMarkers.length) {
        return;
      }

      cleanMarkers(visibleMarkers);

      const newSites = visibleMarkers.filter((site) => {
        const isExistingMarker = markersRef.current.some((marker) => {
          const markerLatLng = new google.maps.LatLng(site.latitude, site.longitude);

          return markerLatLng.equals(marker.getPosition() as google.maps.LatLng);
        });

        return !isExistingMarker;
      });

      renderMarkers(newSites);
    },
    [renderMarkers, map, props.visualization, cleanMarkers],
  );

  useEffect(() => {
    if (map) {
      const loadLocations = () => {
        const visibleBounds = map.getBounds();
        const zoomLevel = map.getZoom();

        if (!visibleBounds || !props.stats || !props.stats.sites.length || !props.visualization) {
          return;
        }

        loadVisibleMarkers(visibleBounds, props.stats.sites);
        setCurrentBounds(visibleBounds);
        setCurrentZoom(Number(zoomLevel));
      };

      google.maps.event.addListener(map, 'idle', loadLocations);

      return () => {
        google.maps.event.clearListeners(map, 'idle');
      };
    }
  }, [props.stats, map, loadVisibleMarkers, props.visualization]);

  // re-render map when props visualization changes
  useEffect(() => {
    if (map && props.stats && props.visualization) {
      markersRef.current.forEach((marker) => marker.setMap(null));
      markersRef.current = [];
      exclusivityZoneMarkersRef.current.forEach((marker) => marker.setMap(null));
      exclusivityZoneMarkersRef.current = [];
      competitorsMarkersRef.current.forEach((marker) => marker.setMap(null));
      competitorsMarkersRef.current = [];

      renderMarkers(props.stats.sites);
    }
  }, [map, props.stats, props.visualization, renderMarkers]);

  useEffect(() => {
    if (props.competitors) {
      renderCompetitors(props.competitors, currentZoom);
    }
  }, [props.competitors, renderCompetitors, currentZoom, props.visualization]);

  // set map bounds to the current visible markers
  useEffect(() => {
    if (map && markersRef.current.length) {
      const bounds = new google.maps.LatLngBounds();
      markersRef.current.forEach((marker) => {
        const position = marker.getPosition();

        if (position) {
          bounds.extend(position);
        }
      });

      map.fitBounds(bounds);

      google.maps.event.addListenerOnce(map, 'idle', () => {
        const zoomLevel = Number(map.getZoom());

        if (zoomLevel > ZOOM_LEVEL) {
          map.setZoom(ZOOM_LEVEL);
          return;
        }
      });
    }
  }, [map, markersRef]);

  useEffect(() => {
    if (selectedMarkerData && map && infoWindowRef.current) {
      if (selectedMarkerData.marker.getMap() === null) {
        setSelectedMarkerData(null);
        return;
      }

      const competitors = (props.competitors || []).filter((competitor) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          selectedMarkerData.marker.getPosition() as google.maps.LatLng,
          new google.maps.LatLng(competitor.geolocation.latitude, competitor.geolocation.longitude),
        );

        if (selectedMarkerData.site.exclusivityZone === 0 || !selectedMarkerData.site.exclusivityZone) {
          return false;
        }

        return distance <= selectedMarkerData.site.exclusivityZone && competitor.type === 'BLOCKED_COMPETITORS';
      });

      infoWindowRef.current.setContent(
        renderTooltip(Object.assign({}, selectedMarkerData.site, { competitors: competitors.length })),
      );
      infoWindowRef.current.open({
        anchor: selectedMarkerData.marker,
        map,
        shouldFocus: false,
      });

      infoWindowRef.current.addListener('closeclick', () => {
        setSelectedMarkerData(null);
      });
    }

    return () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.close();
      }
    };
  }, [selectedMarkerData, map, infoWindowRef, renderTooltip, props.competitors]);

  return (
    <>
      <style>{MAP_STYLES}</style>
      <div
        ref={mapContainerRef as React.RefObject<HTMLDivElement>}
        id={props.id}
        style={{
          aspectRatio: props.aspectRatio || '2.5 / 1',
          border: `1px solid ${colors.neutral[200]}`,
          borderRadius: '8px',
          ...(props.aspectRatio && { '@supports not (aspectRatio: 1 / 1)': { height: '400px' } }),
        }}
      >
        {competitorsStatus === Status.Loading && (
          <Flex
            position='absolute'
            zIndex={3}
            right={2}
            top={2}
            alignItems='center'
            bg='rgba(0, 0, 0, 0.7)'
            color='white'
            p={3}
            borderRadius='md'
            gap={3}
          >
            <Spinner color='blue.400' size='md' thickness='2px' /> Loading competitors...
          </Flex>
        )}
      </div>
      {map && mapIsReady && props.visualization !== MapVisualizations.Competitors && (
        <MapLegend
          map={map}
          min={minRevenue}
          max={maxRevenue}
          colorMin={formatColor(LEGEND_COLORS[0].color)}
          colorMiddle={formatColor(LEGEND_COLORS[1].color)}
          colorMax={formatColor(LEGEND_COLORS[2].color)}
          activeVertical={props.vertical}
          transactionType={props.transactionType}
        />
      )}
    </>
  );
};

export { LocationsMap };
