import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle } from 'react-icons/fi';
import { Box, Flex, Text, Wrap } from '@chakra-ui/react';
import { Card } from '@components/card';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Section } from '@/common/components/section';
import { Status } from '@/common/interfaces';

import { selectActiveVertical, selectAuthRequestPath, selectUserOrg, selectUserRoles } from '../auth/userSlice';
import { selectLocationsFilter } from '../dashboard/slice';
import { selectLocationsSettings } from '../settings/locations/slice';
import { selectBillingMethods } from '../settings/payment/billingPaymentMethods/slice';
import {
  selectUserRegistration,
  selectUserRegistrationStatus,
  userRegistrationFetchRequest,
} from '../settings/profile/slice';

export const Onboarding = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authRequestPath = useAppSelector(selectAuthRequestPath);
  const activeVertical = useAppSelector(selectActiveVertical);
  const roles = useAppSelector(selectUserRoles);
  const orgId = useAppSelector(selectUserOrg);
  const locations = useAppSelector(selectLocationsFilter);
  const locationsSettings = useAppSelector(selectLocationsSettings);
  const billingPaymentMethods = useAppSelector(selectBillingMethods);
  const userRegistration = useAppSelector(selectUserRegistration);

  const locationsStepCompleted = userRegistration.locationsStepCompleted;
  const paymentsStepCompleted = userRegistration.paymentsStepCompleted;
  const skipPaymentsStep = userRegistration.skipPaymentsStep;
  const isDefaultWorkflow = userRegistration.isDefaultWorkflow;
  const loadingStatus = useAppSelector(selectUserRegistrationStatus);
  const [isLocationsStepCompleted, setIsLocationStepCompleted] = useState(locationsStepCompleted);
  const [isPaymentsStepCompleted, setIsPaymentsStepCompleted] = useState(paymentsStepCompleted);
  const [isPendingLaunch, setIsPendingLaunch] = useState(false);

  const isLocationLaunched = locations.some((location) => location.site_network_join_date !== null);

  const isLocationAdded = Array.isArray(locationsSettings) && locationsSettings.length > 0;

  const hasCards = Array.isArray(billingPaymentMethods.cards) && billingPaymentMethods.cards.length > 0;

  const hasVerifiedBankAccount =
    Array.isArray(billingPaymentMethods.bank_accounts) &&
    billingPaymentMethods.bank_accounts.some((account) => account.status === 'verified');

  const isBillingMethodAdded = hasCards || hasVerifiedBankAccount;

  useEffect(() => {
    dispatch(
      userRegistrationFetchRequest({
        authRequestPath,
        data: { isLocationLaunched, isLocationAdded, isBillingMethodAdded, roles, orgId, activeVertical },
      }),
    );
    setIsLocationStepCompleted(isLocationAdded);
    setIsPaymentsStepCompleted(isBillingMethodAdded);
  }, [isLocationAdded, isBillingMethodAdded]);

  useEffect(() => {
    // All steps are completed but a location is not launched
    const allStepsCompleted = isLocationsStepCompleted && isPaymentsStepCompleted && !isLocationLaunched;

    // Streamlined workflow - location step is completed but not launched
    const streamlinedWorkflow = !isDefaultWorkflow && isPaymentsStepCompleted && !isLocationLaunched;

    // Skip payments step - payments step is completed but no location is launched
    const skipPayments = skipPaymentsStep && isLocationsStepCompleted && !isLocationLaunched;

    // Edge case where streamlined workflow and skip payments is selected but no location is launched
    const allStepsSkipped = !isDefaultWorkflow && skipPayments && !isLocationLaunched;

    setIsPendingLaunch(allStepsCompleted || streamlinedWorkflow || skipPaymentsStep || allStepsSkipped);
  }, [isLocationsStepCompleted, paymentsStepCompleted, isLocationLaunched, isDefaultWorkflow, skipPaymentsStep]);

  return (
    <Box padding={0} margin={0} maxW='100%'>
      {loadingStatus === Status.Loading && (
        <Flex alignItems='center' justifyContent='center' mt={10}>
          <LoadingSpinner />
        </Flex>
      )}
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus === Status.Idle && (
        <>
          {isPendingLaunch && (
            <Box padding={0} margin={0} maxW='100%'>
              <>
                <Wrap direction='column' gap={2} padding={2}>
                  <Card bg='green.200'>
                    <Flex gap={4} justifyContent='center' alignItems='center' height='100%'>
                      <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
                      {isDefaultWorkflow && !skipPaymentsStep && isLocationsStepCompleted && isPaymentsStepCompleted && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'} verticalAlign={'self'}>
                          {t('onboardingPage.complete.titleDefault')}
                        </Text>
                      )}
                      {isDefaultWorkflow && skipPaymentsStep && isLocationsStepCompleted && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'} verticalAlign={'self'}>
                          {t('onboardingPage.complete.titleSkipPayments')}
                        </Text>
                      )}
                      {!isDefaultWorkflow && !skipPaymentsStep && isPaymentsStepCompleted && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'}>
                          {t('onboardingPage.complete.titleStreamlined')}
                        </Text>
                      )}
                      {!isDefaultWorkflow && skipPaymentsStep && (
                        <Text fontWeight={500} fontSize={'18px'} lineHeight={'24px'}>
                          {t('onboardingPage.complete.titleSkipAll')}
                        </Text>
                      )}
                    </Flex>
                  </Card>
                </Wrap>
              </>
            </Box>
          )}
          {isDefaultWorkflow && (
            <Box padding={0} margin={0} maxW='100%'>
              <>
                <Wrap direction='column' gap={2} padding={2}>
                  <Wrap direction='column'>
                    <Section
                      name={
                        !isLocationsStepCompleted
                          ? 'onboardingPage.locations.title'
                          : 'onboardingPage.locations.titleCompleted'
                      }
                    >
                      <Flex justifyContent='center' gap={4}>
                        <Card
                          button={{
                            text: isLocationsStepCompleted ? t('buttons.edit') : t('buttons.start'),
                            to: '/settings/locations',
                            variant: 'brandPrimary',
                          }}
                        >
                          <Flex justifyContent='space-between' gap={4}>
                            <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                              {t('onboardingPage.locations.text')}
                            </Text>
                            {isLocationsStepCompleted && (
                              <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
                            )}
                          </Flex>
                        </Card>
                      </Flex>
                    </Section>
                  </Wrap>
                </Wrap>
              </>
            </Box>
          )}
          {!skipPaymentsStep && (
            <Box padding={0} margin={0} maxW='100%'>
              <>
                <Wrap direction='column' gap={2} padding={2}>
                  <Wrap direction='column'>
                    <Section
                      name={
                        !isPaymentsStepCompleted
                          ? 'onboardingPage.payments.title'
                          : 'onboardingPage.payments.titleCompleted'
                      }
                    >
                      <Flex justifyContent='center' gap={4}>
                        <Card
                          button={{
                            text: isPaymentsStepCompleted ? t('buttons.edit') : t('buttons.start'),
                            to: '/settings/payments',
                            variant: 'brandPrimary',
                          }}
                        >
                          <Flex justifyContent='space-between' gap={4}>
                            <Text fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                              {t('onboardingPage.payments.text')}
                            </Text>
                            {isPaymentsStepCompleted && (
                              <FiCheckCircle size='24' color='#4CAF50' style={{ marginRight: '12px' }} />
                            )}
                          </Flex>
                        </Card>
                      </Flex>
                    </Section>
                  </Wrap>
                </Wrap>
              </>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
