import { all } from 'typed-redux-saga';

import { watchBehavior } from '@/features/explore/behavior/sagas';
import { watchCapacity } from '@/features/explore/capacity/sagas';
import { watchCompetitors, watchLocations } from '@/features/explore/locations/sagas';
import { watchUpdateUserRegistration, watchUserRegistration } from '@/features/onboarding/sagas';
import { watchRegistration } from '@/features/registration/sagas';
import { watchCustomReport, watchCustomReportColumns } from '@/features/reports/customReports/sagas';
import { watchInvoices } from '@/features/reports/financialReports/invoices/sagas';
import { watchPayouts } from '@/features/reports/financialReports/payouts/sagas';
import { watchStatements } from '@/features/reports/financialReports/statements/sagas';
import {
  watchAddNewLocation,
  watchLocationsSettingsList,
  watchUpdateLocation,
} from '@/features/settings/locations/saga';
import { watchNotification, watchUpdateNotification } from '@/features/settings/notification/saga';
import {
  watchBillingMethods,
  watchBillingPlaidToken,
  watchDeleteBillingMethod,
  watchNewBillingMethod,
  watchUpdateBillingMethod,
  watchVerifyBillingPaymentMethod,
} from '@/features/settings/payment/billingPaymentMethods/sagas';
import {
  watchAddNewPayout,
  watchDeletePayout,
  watchPayments,
  watchPlaidToken,
  watchUpdatePayout,
} from '@/features/settings/payment/payoutMethod/sagas';
import {
  watchCreateUserProfile,
  watchUpdatePassword,
  watchUpdateUserProfile,
  watchUserProfile,
  watchUserProfiles,
} from '@/features/settings/profile/sagas';

import {
  watchGeneralConfigs,
  watchHeaderData,
  watchMasqueradingLists,
  watchParentOrgConfig,
  watchPerformanceSummary,
} from '../features/dashboard/sagas';
import { watchCustomers } from '../features/explore/customerDeepDive/sagas';
import { watchPromotions } from '../features/reports/promotions/sagas';

export default function* rootSaga() {
  yield* all([
    watchRegistration(),
    watchHeaderData(),
    watchGeneralConfigs(),
    watchMasqueradingLists(),
    watchParentOrgConfig(),
    watchPerformanceSummary(),
    watchCustomers(),
    watchPromotions(),
    watchCapacity(),
    watchLocations(),
    watchCompetitors(),
    watchBehavior(),
    watchInvoices(),
    watchStatements(),
    watchPayouts(),
    watchPlaidToken(),
    watchPayments(),
    watchAddNewPayout(),
    watchUpdatePayout(),
    watchDeletePayout(),
    watchNewBillingMethod(),
    watchBillingMethods(),
    watchUpdateBillingMethod(),
    watchDeleteBillingMethod(),
    watchBillingPlaidToken(),
    watchVerifyBillingPaymentMethod(),
    watchUserProfile(),
    watchUserProfiles(),
    watchUpdateUserProfile(),
    watchCreateUserProfile(),
    watchLocationsSettingsList(),
    watchAddNewLocation(),
    watchUpdateLocation(),
    watchUpdatePassword(),
    watchNotification(),
    watchUpdateNotification(),
    watchCustomReportColumns(),
    watchCustomReport(),
    watchUserRegistration(),
    watchUpdateUserRegistration(),
  ]);
}
