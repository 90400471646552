import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';

import { IPlaidToken } from '../hooks';

import { AddPayoutFormType, IPaymentsRawData } from './types';

export interface IPaymentState {
  plaidToken: IPlaidToken;
  error?: string;
  payoutStatus: Status;
  payoutMethods: IPaymentsRawData[];
  newPayout?: AddPayoutFormType;
}

export const initialState: IPaymentState = {
  plaidToken: { link_token: '', expiration: 0 },
  payoutStatus: Status.Idle,
  payoutMethods: [],
  newPayout: undefined,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPayoutLoadingState: (state, action: PayloadAction<Status>) => {
      state.payoutStatus = action.payload;
    },
    setPlaidToken: (state, action: PayloadAction<IPlaidToken>) => {
      state.plaidToken = action.payload;
    },
    setNewPayoutData: (state, action: PayloadAction<AddPayoutFormType>) => {
      state.newPayout = action.payload;
    },
    plaidTokenFetchRequest: (state) => {
      state.error = undefined;
      state.payoutStatus = Status.Loading;
    },
    plaidTokenSucceeded: (state, action: PayloadAction<{ token_data: IPlaidToken }>) => {
      state.plaidToken = action.payload.token_data;
      state.payoutStatus = Status.Idle;
    },
    plaidTokenFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.payoutStatus = Status.Failed;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paymentsFetchRequest: (state, _action: PayloadAction<any>) => {
      state.error = undefined;
      state.payoutStatus = Status.Loading;
    },
    paymentsSucceeded: (state, action: PayloadAction<IPaymentsRawData[]>) => {
      state.payoutMethods = action.payload;
      state.payoutStatus = Status.Idle;
    },
    paymentsFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      console.error('paymentsFailed', action.payload);
      state.payoutStatus = Status.Failed;
    },
    addNewPayoutRequest: (state) => {
      state.error = undefined;
      state.payoutStatus = Status.Loading;
    },
    addNewPayoutSucceeded: (state) => {
      state.newPayout = undefined;
    },
    addNewPayoutFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.payoutStatus = Status.Failed;
    },
    updatePayoutRequest: (state) => {
      state.error = undefined;
      state.payoutStatus = Status.Loading;
    },
    updatePayoutSucceeded: (state) => {
      state.newPayout = undefined;
    },
    updatePayoutFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.payoutStatus = Status.Failed;
    },
    deletePayoutRequest: (state) => {
      state.error = undefined;
      state.payoutStatus = Status.Loading;
    },
    deletePayoutSucceeded: (state) => {
      state.newPayout = undefined;
    },
    deletePayoutFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.payoutStatus = Status.Failed;
    },
  },
});

export const {
  plaidTokenFailed,
  plaidTokenSucceeded,
  plaidTokenFetchRequest,
  paymentsFetchRequest,
  paymentsSucceeded,
  paymentsFailed,
  setPlaidToken,
  setNewPayoutData,
  addNewPayoutRequest,
  addNewPayoutSucceeded,
  addNewPayoutFailed,
  updatePayoutRequest,
  updatePayoutSucceeded,
  updatePayoutFailed,
  deletePayoutRequest,
  deletePayoutSucceeded,
  deletePayoutFailed,
} = paymentSlice.actions;

export const selectPlaidToken = (state: RootState) => state.payment.plaidToken;
export const selectPayoutMethods = (state: RootState) => state.payment.payoutMethods;
export const selectPayoutLoadingStatus = (state: RootState) => state.payment.payoutStatus;

export default paymentSlice.reducer;
