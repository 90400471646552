import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Table as ChakraTable, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';

import { useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { Pagination, PaginationContainer, PaginationProps } from '@/common/components/pagination';
import { usePermissions } from '@/common/hooks';
import { useFetchLocationSettings } from '@/common/hooks/fetchDashboardData';
import { Status, Verticals } from '@/common/interfaces';
import { selectActiveVertical, selectIsUpsideManager } from '@/features/auth/userSlice';

import { selectIsOnboarding } from '../profile/slice';

import { LocationsTableCell } from './components/LocationsTableCell';
import { selectLocationsSettings, selectLocationsSettingsStatus } from './slice';
import { LocationSettingsData } from './types';

export function LocationsSettings() {
  const { t } = useTranslation();
  const { hasLocationsSettingsManagerAccess } = usePermissions();

  useFetchLocationSettings();

  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const loadingStatus = useAppSelector(selectLocationsSettingsStatus);
  const locations = useAppSelector(selectLocationsSettings);
  const activeVertical = useAppSelector(selectActiveVertical);
  const isOnboarding = useAppSelector(selectIsOnboarding);
  const navigate = useNavigate();

  return (
    <Box padding={4}>
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus === Status.Loading && <LoadingSpinner />}
      {loadingStatus === Status.Idle && (
        <PaginationContainer
          data={locations || []}
          options={{
            pageSize: 5,
            availablePageSizes: [5, 10, 20, 50, 100],
          }}
        >
          {(props: PaginationProps) => {
            const paginatedData = props.data as LocationSettingsData[];

            return (
              <>
                <Box>
                  {isOnboarding && (
                    <Button
                      leftIcon={<ChevronLeftIcon fontSize={23} />}
                      mb={2}
                      px={0}
                      variant='accentTertiary'
                      size='md'
                      onClick={() => navigate('/onboarding')}
                    >
                      {t('onboardingPage.onboarding')}
                    </Button>
                  )}
                  <Flex justifyContent='flex-end' mb={6}>
                    {(hasLocationsSettingsManagerAccess || isUpsideManager) && (
                      <Button
                        as={Link}
                        to={activeVertical === Verticals.Fuel ? 'new/1' : 'new'}
                        variant='brandPrimary'
                        size='md'
                        leftIcon={<AddIcon />}
                      >
                        {t('locationsSettingsPage.addLocation')}
                      </Button>
                    )}
                  </Flex>
                </Box>
                <TableContainer width={'100%'} overflowX={'hidden'} mt={2}>
                  <ChakraTable style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
                    <Thead>
                      <Tr>
                        <Th width='80%'>{t('locationsSettingsPage.locationsTbl.locationName')}</Th>
                        <Th>{t('locationsSettingsPage.locationsTbl.locationStatus')}</Th>
                        {(hasLocationsSettingsManagerAccess || isUpsideManager) && (
                          <Th textAlign='right'>{t('locationsSettingsPage.locationsTbl.locationActions')}</Th>
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {paginatedData.map((row) => (
                        <LocationsTableCell key={row.id} data={row} />
                      ))}
                    </Tbody>
                  </ChakraTable>
                </TableContainer>
                <Pagination {...props} />
              </>
            );
          }}
        </PaginationContainer>
      )}
    </Box>
  );
}
