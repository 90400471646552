import { z } from 'zod';

export const accountRegistrationSchema = z.object({
  formData: z.object({
    merchantLegalName: z
      .string()
      .min(3)
      .regex(/^[a-zA-Z0-9\s\-',&.]+$/, {
        message:
          'Merchant name can only contain alphanumeric characters, spaces, hyphens, apostrophes, commas, ampersands, and periods',
      }),
    vertical: z.enum(['fuel', 'grocery', 'restaurant']),
    firstName: z.string().optional(),
    middleInitial: z.string().default(''),
    lastName: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().optional(),
    isDefaultWorkflow: z.boolean(),
    acceptTerms: z.boolean(),
  }),
  hasClickedLink: z.boolean().optional(),
});

export const contactInfoSchema = z.object({
  formData: z.object({
    merchantLegalName: z.string().optional(),
    vertical: z.enum(['fuel', 'grocery', 'restaurant']),
    firstName: z.string().regex(/^[a-zA-Z]+$/, { message: 'First name can only contain alphabetical characters' }),
    middleInitial: z.string().default(''),
    lastName: z.string().regex(/^[a-zA-Z]+$/, { message: 'Last name can only contain alphabetical characters' }),
    phone: z
      .string()
      .regex(/^\+?[1-9][0-9\s\-().]{1,14}$/, {
        message: 'Phone number can contain only digits, spaces, parentheses, dashes, plus signs, and dots',
      })
      .min(10, { message: 'Phone number must be at least 10 characters long' }),
    email: z.string().optional(),
    isDefaultWorkflow: z.boolean(),
    acceptTerms: z.boolean(),
  }),
  hasClickedLink: z.boolean().optional(),
});

export const loginDetailsSchema = z
  .object({
    formData: z.object({
      merchantLegalName: z.string().optional(),
      vertical: z.enum(['fuel', 'grocery', 'restaurant']),
      firstName: z.string().optional(),
      middleInitial: z.string().default(''),
      lastName: z.string().optional(),
      phone: z.string().optional(),
      email: z.string().email(),
      isDefaultWorkflow: z.boolean(),
      acceptTerms: z.boolean(),
    }),
    hasClickedLink: z.boolean(),
  })
  .refine(
    (loginDetailsFormData) => {
      if (loginDetailsFormData.formData.isDefaultWorkflow)
        return loginDetailsFormData.formData.acceptTerms === true && loginDetailsFormData.hasClickedLink === true;
      return true;
    },
    {
      message:
        'You must click the link to view the terms and conditions, and then check the box to confirm your acceptance before submitting the form.',
      path: ['formData.acceptTerms'],
    },
  );
