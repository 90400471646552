import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { Status } from '@/common/interfaces';

import { Profile, UserProfile, userProfileFormData, userRegistrationFormData } from './types';

export interface ProfileState {
  profile: UserProfile;
  profiles: [Profile] | [];
  profileStatus: Status;
  profilesStatus: Status;
  registrationStatus: Status;
  passwordStatus: Status;
  error?: string;
}

export const initialState: ProfileState = {
  profile: {
    userProfile: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    userRegistration: {
      isOnboarding: false,
      isDefaultWorkflow: true,
      locationsStepCompleted: false,
      paymentsStepCompleted: false,
      skipPaymentsStep: false,
    },
  },
  profiles: [],
  profileStatus: Status.Idle,
  registrationStatus: Status.Idle,
  profilesStatus: Status.Idle,
  passwordStatus: Status.Idle,
  error: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userProfileFetchRequest: (state, _action: PayloadAction<any>) => {
      state.profileStatus = Status.Loading;
      state.error = undefined;
    },
    userProfileFetchSuccess: (state, action: PayloadAction<UserProfile>) => {
      state.profileStatus = Status.Idle;
      state.profile = action.payload;
    },
    userProfileFetchFailure: (state, _action: PayloadAction<string>) => {
      state.profileStatus = Status.Failed;
      state.error = 'Failed to fetch user data';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userRegistrationFetchRequest: (state, _action: PayloadAction<any>) => {
      state.registrationStatus = Status.Loading;
      state.error = undefined;
    },
    userRegistrationFetchSuccess: (state, action: PayloadAction<UserProfile>) => {
      state.registrationStatus = Status.Idle;
      state.profile.userRegistration = action.payload.userRegistration;
    },
    userRegistrationFetchFailure: (state, _action: PayloadAction<string>) => {
      state.registrationStatus = Status.Failed;
      state.error = 'Failed to fetch user registration data';
    },
    updateUserRegistrationRequest: (
      state,
      _action: PayloadAction<{
        authRequestPath: string;
        data: userRegistrationFormData;
      }>,
    ) => {
      state.registrationStatus = Status.Loading;
      state.error = undefined;
    },
    updateUserRegistrationSucceeded: (state) => {
      state.error = undefined;
      state.registrationStatus = Status.Idle;
    },
    updateUserRegistrationFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.registrationStatus = Status.Failed;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userProfilesFetchRequest: (state, _action: PayloadAction<any>) => {
      state.profilesStatus = Status.Loading;
      state.error = undefined;
    },
    userProfilesFetchSuccess: (state, action: PayloadAction<[Profile]>) => {
      state.profilesStatus = Status.Idle;
      state.profiles = action.payload;
    },
    userProfilesFetchFailure: (state, _action: PayloadAction<string>) => {
      state.profilesStatus = Status.Failed;
      state.error = 'Failed to fetch user data';
    },
    updateUserProfileRequest: (
      state,
      _action: PayloadAction<{
        authRequestPath: string;
        data: userProfileFormData;
      }>,
    ) => {
      state.profileStatus = Status.Loading;
      state.error = undefined;
    },
    updateUserProfileSucceeded: (state) => {
      state.error = undefined;
    },
    updateUserProfileFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.profileStatus = Status.Failed;
    },
    createUserProfileRequest: (state) => {
      state.error = undefined;
      state.profileStatus = Status.Loading;
    },
    createUserProfileSucceeded: (state) => {
      state.error = undefined;
    },
    createUserProfileFailed: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.profileStatus = Status.Failed;
    },
    passwordUpdateRequest: (state) => {
      state.passwordStatus = Status.Loading;
    },
    passwordUpdateSuccess: (state) => {
      state.passwordStatus = Status.Idle;
    },
    passwordUpdateFailed: (state, action: PayloadAction<string>) => {
      state.passwordStatus = Status.Failed;
      state.error = action.payload;
    },
  },
});

export const {
  userProfileFetchRequest,
  userProfileFetchSuccess,
  userProfileFetchFailure,
  userProfilesFetchRequest,
  userProfilesFetchSuccess,
  userProfilesFetchFailure,
  updateUserProfileFailed,
  updateUserProfileRequest,
  updateUserProfileSucceeded,
  createUserProfileFailed,
  createUserProfileRequest,
  createUserProfileSucceeded,
  passwordUpdateRequest,
  passwordUpdateSuccess,
  passwordUpdateFailed,
  userRegistrationFetchRequest,
  userRegistrationFetchSuccess,
  userRegistrationFetchFailure,
  updateUserRegistrationFailed,
  updateUserRegistrationRequest,
  updateUserRegistrationSucceeded,
} = profileSlice.actions;

// Profile selectors
export const selectUserProfile = (state: RootState) => state.profile.profile.userProfile;
export const selectUserProfileStatus = (state: RootState) => state.profile.profileStatus;
export const selectUserRegistration = (state: RootState) => state.profile.profile.userRegistration;
export const selectUserRegistrationStatus = (state: RootState) => state.profile.registrationStatus;
export const selectIsOnboarding = (state: RootState) =>
  !!(state.profile.profile.userRegistration?.isOnboarding || false);

// Profiles selectors
export const selectUserProfilesStatus = (state: RootState) => state.profile.profilesStatus;
export const selectUserProfiles = (state: RootState) => state.profile.profiles;

export default profileSlice.reducer;
