import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, SimpleGrid, Spacer, Stack, useBreakpointValue } from '@chakra-ui/react';
import { Card } from '@components/card';
import { DualMetricCard } from '@components/dualMetricCard';
import { EmptyDataAlert } from '@components/errorAlert';
import { Section } from '@components/section';
import { TabView } from '@components/tabView';
import { useDecision } from '@optimizely/react-sdk';
import _ from 'lodash';

import { Status, TransactionTypes, Verticals } from '@/common/interfaces';
import { ENV_CONFIG } from '@/config';

import { selectActiveVertical, selectUserStatus } from '../auth/userSlice';
import { AnnouncementBanner } from '../dashboard/components/announcementBanner';
import { MaintenanceBanner } from '../dashboard/components/maintenanceBanner';
import { SunocoBanner } from '../dashboard/components/sunocoBanner';
import {
  selectActiveProgramBasicsTab,
  selectContentStatus,
  selectLocationsFilter,
  selectPerformanceSummary,
  selectSelectedTransactionType,
  selectSunocoSites,
  selectTemporalUnit,
  setProgramBasicsTab,
} from '../dashboard/slice';
import { IRefinedPerformanceSummary } from '../dashboard/types';
import { CapacityInfoLink } from '../explore/capacity/CapacityInfo';
import { selectCapacity, selectCapacityStatus } from '../explore/capacity/slice';
import {
  selectCustomersSegmentationSummary,
  selectCustomersSegmentationSummaryStatus,
} from '../explore/customerDeepDive/slice';
import { selectSiteLevelPerformance } from '../explore/locations/slice';

import { CardViewContents } from './CardsContentGenerator';
import { tabViewContentGrocery, tabViewContentMV } from './TabPanelGenerator';

export const Home = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const temporalUnit = useAppSelector(selectTemporalUnit);
  const activeProgramBasicsTab = useAppSelector(selectActiveProgramBasicsTab);
  const activeVertical = useAppSelector(selectActiveVertical);
  const capacityStatus = useAppSelector(selectCapacityStatus);
  const capacityData = useAppSelector(selectCapacity);
  const transactionTypes = useAppSelector(selectSelectedTransactionType);
  const contentStatus = useAppSelector(selectContentStatus);
  const userStatus = useAppSelector(selectUserStatus);
  const allLocationSites = useAppSelector(selectLocationsFilter);
  const sunocoSites = useAppSelector(selectSunocoSites);
  const siteLevelPerformance = useAppSelector(selectSiteLevelPerformance);
  const customersSegmentationStatus = useAppSelector(selectCustomersSegmentationSummaryStatus);
  const summaryGraphData: IRefinedPerformanceSummary = useAppSelector(selectPerformanceSummary);
  const customersSegmentationSummary = useAppSelector(selectCustomersSegmentationSummary);
  const mapAspectRatio = useBreakpointValue({ base: '1/1', sm: '2/1', md: '2/1', lg: '2.5 / 1', xl: '1.4/1' });
  const [capacityFlag] = useDecision('md-569', { autoUpdate: true });
  const [behaviorFlag] = useDecision('md-640', { autoUpdate: true });
  const [reachTabFlag] = useDecision('fe-home-reach-tab', { autoUpdate: true });

  const [selectedMetric, setSelectedMetric] = useState('totalUpsideCustomers');

  const isLoading = [contentStatus, userStatus, customersSegmentationStatus].includes(Status.Loading);
  const isError = [contentStatus, userStatus, customersSegmentationStatus].includes(Status.Failed);
  const isEmptyData = !isLoading && !isError && siteLevelPerformance?.topSitesTable.rows?.length === 0;

  const handleTabChange = (tabIndex: number) => {
    dispatch(setProgramBasicsTab(tabIndex));
  };

  const showSunocoBanner = () => {
    const currentSitesSet = new Set();
    for (const site of allLocationSites) {
      if (site.relationship_type === 'CURRENT') {
        currentSitesSet.add(site.site_uuid);
      }
    }

    for (const site_uuid of sunocoSites) {
      if (currentSitesSet.has(site_uuid)) {
        return true;
      }
    }
    return false;
  };

  const displayCapacity = () => {
    if (
      (ENV_CONFIG.TIER === 'prod' && activeVertical === Verticals.Fuel) ||
      (ENV_CONFIG.TIER === 'alpha' && activeVertical !== Verticals.Restaurant)
    ) {
      return (
        <Section>
          {_.isEmpty(capacityData) && capacityStatus === Status.Idle ? (
            <EmptyDataAlert />
          ) : (
            <Card>
              <CardViewContents.CapacityByLocation
                capacityStats={capacityData.statCards?.slice(0, 2)}
                capacityLocationsList={capacityData.capacityByLocation?.operating.barChart}
                capacityGauge={capacityData.capacityByLocation?.operating.gauge}
                capacityStatus={capacityStatus}
              />
              <Flex mt={'20px'}>
                <CapacityInfoLink />
              </Flex>
            </Card>
          )}
        </Section>
      );
    } else return;
  };

  return (
    <>
      <Box maxW='100%'>
        {showSunocoBanner() && <SunocoBanner />}
        <MaintenanceBanner />
        <AnnouncementBanner />
        <Section name='homePage.upsideImpact.label'></Section>
        {activeVertical === Verticals.Fuel && transactionTypes !== TransactionTypes.CStore && (
          <Flex direction={{ base: 'column', lg: 'row' }} gap='4'>
            <DualMetricCard
              leftMetricContent={CardViewContents.gallonsOnUpside(summaryGraphData, contentStatus)}
              rightMetricContent={CardViewContents.incrementalGallons(summaryGraphData, contentStatus)}
            />
          </Flex>
        )}
        <Flex
          align='stretch'
          direction={{ base: 'column', xl: 'row', lg: 'column', md: 'column', sm: 'column' }}
          gap={{ base: 4, xl: 4, lg: 0, md: 0, sm: 0 }}
          mb={14}
        >
          <DualMetricCard
            leftMetricContent={CardViewContents.salesOnUpside(summaryGraphData, contentStatus)}
            rightMetricContent={CardViewContents.incrementalSales(summaryGraphData, contentStatus)}
          />
          <Spacer />
          <DualMetricCard
            leftMetricContent={CardViewContents.totalProgramCosts(summaryGraphData, contentStatus)}
            rightMetricContent={CardViewContents.incrementalNetProfit(summaryGraphData, contentStatus)}
          />
        </Flex>

        <Section name='homePage.customerAnalytics.label'>
          <CardViewContents.CustomerAnalyticsCharts
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            loadingStatus={customersSegmentationStatus}
            customersSegmentationSummary={customersSegmentationSummary}
          />
        </Section>

        <Section name='homePage.programBasics.label'>
          {isEmptyData ? (
            <EmptyDataAlert />
          ) : (
            <Card>
              <TabView
                tabViewContent={
                  activeVertical !== 'grocery'
                    ? tabViewContentMV(behaviorFlag.enabled, reachTabFlag.enabled)
                    : tabViewContentGrocery(reachTabFlag.enabled)
                }
                data={summaryGraphData}
                segmentData={customersSegmentationSummary}
                loadingStatus={contentStatus}
                onChangeTab={handleTabChange}
                activeTab={activeProgramBasicsTab}
              />
            </Card>
          )}
        </Section>

        <Section name='homePage.exploreMore.label'>
          {isEmptyData ? (
            <EmptyDataAlert />
          ) : (
            <Stack direction={{ base: 'column', lg: 'row' }}>
              <Card
                button={{
                  text: t('homePage.exploreMore.allLocationsBtn'),
                  to: '/explore/locations',
                  icon: ArrowForwardIcon,
                }}
                loadingStatus={contentStatus}
                variant='topBorder'
              >
                <CardViewContents.YourLocations
                  siteLevelPerformance={siteLevelPerformance}
                  loadingStatus={contentStatus}
                  mapAspectRatio={mapAspectRatio}
                />
              </Card>
            </Stack>
          )}
          <SimpleGrid columns={{ md: 1, lg: 2 }} spacingX={4} spacingY={3}>
            <Card
              button={{
                text: t('homePage.exploreMore.learnMoreBtn'),
                to: '/reports/promotions',
                icon: ArrowForwardIcon,
              }}
              loadingStatus={contentStatus}
              link={
                activeVertical === Verticals.Fuel &&
                (transactionTypes === TransactionTypes.All || transactionTypes === TransactionTypes.Gas) && {
                  href: 'https://fuelknowledgecenter.upside.com/hc/en-us/articles/10720486081307-How-Upside-protects-your-profit-as-margins-shift',
                  key: 'promotionsPage.promoDistGraph.protectProfitMarginFaqLink',
                }
              }
            >
              <CardViewContents.AvgAcceptedOffer
                homeGraphData={summaryGraphData}
                loadingStatus={contentStatus}
                temporalUnit={temporalUnit}
                activeVertical={activeVertical}
              />
            </Card>
            <Card
              button={{
                text: t('homePage.exploreMore.learnMoreBtn'),
                to: '/reports/profitability',
                icon: ArrowForwardIcon,
              }}
              loadingStatus={contentStatus}
            >
              <CardViewContents.RoiFlowthrough homeGraphData={summaryGraphData} loadingStatus={contentStatus} />
            </Card>
          </SimpleGrid>
          <Stack>
            <Card
              button={{
                text: t('homePage.recentCustomers.exploreIndCustomerBtn'),
                to: '/explore/customerDeepDive',
                icon: ArrowForwardIcon,
              }}
              loadingStatus={contentStatus}
              variant='borderless'
            >
              {activeVertical === Verticals.Grocery ? (
                <CardViewContents.CustomerList loadingStatus={contentStatus} />
              ) : (
                <CardViewContents.RecentTransactionsList loadingStatus={contentStatus} />
              )}
            </Card>
          </Stack>
        </Section>

        {capacityFlag.enabled && displayCapacity()}
        {/* End of Content Page */}
      </Box>
    </>
  );
};
