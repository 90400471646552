import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import { useAppSelector } from '@/app/hooks';
import { DataLoadingErrorPanel, EmptyDataAlertPanel } from '@/common/components/errorAlert';
import { LoadingSpinner } from '@/common/components/loadingSpinner';
import { useFetchUserProfiles } from '@/common/hooks/fetchUserSettings';
import { Status } from '@/common/interfaces';

import { ContactInfo } from './components/ContactInfo';
import { ProfileTableCell } from './components/ProfileTableCell';
import { selectUserProfiles, selectUserProfilesStatus } from './slice';
import { Profile } from './types';

export const Profiles = () => {
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);

  const loadingStatus = useAppSelector(selectUserProfilesStatus);
  const userProfiles = useAppSelector(selectUserProfiles);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useFetchUserProfiles();

  const handleRowClick = (profile: Profile) => {
    setSelectedProfile(profile);
    onOpen();
  };

  const isEmpty = loadingStatus === Status.Idle && (!userProfiles || userProfiles.length === 0);

  return (
    <Box padding={4}>
      {loadingStatus === Status.Loading && (
        <Flex alignItems='center' justifyContent='center' mt={10}>
          <LoadingSpinner />
        </Flex>
      )}
      {loadingStatus === Status.Failed && <DataLoadingErrorPanel />}
      {loadingStatus === Status.Idle && (
        <>
          <Card variant='borderless'>
            {isEmpty ? (
              <EmptyDataAlertPanel
                altMessage={{ title: 'profilesPage.noData.title', message: 'profilesPage.noData.message' }}
              />
            ) : (
              <>
                <TableContainer width={'100%'} overflowX={'hidden'} mt={5}>
                  <Tooltip hasArrow label={`Click to update`} placement='top'>
                    <ChakraTable style={{ borderCollapse: 'separate', borderSpacing: '0' }}>
                      <Thead>
                        <Tr>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore */}
                          {Object.keys(userProfiles[0]).map(
                            (header) => header !== 'sortKey' && <Th key={header}>{t(header)}</Th>,
                          )}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {userProfiles.map((profile: Profile, index) => (
                          <Tr
                            key={index}
                            onClick={() => handleRowClick(profile)}
                            cursor={'pointer'}
                            _hover={{ bg: 'blue.100 !important' }} // doesn't work for odd rows - noting that there, not a blocker
                          >
                            {Object.entries(profile).map(
                              ([key, value], colId) =>
                                key !== 'sortKey' && <ProfileTableCell key={colId} data={value} />,
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </ChakraTable>
                  </Tooltip>
                </TableContainer>
              </>
            )}
          </Card>
          {/* Modal */}
          {selectedProfile && (
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Profile Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <ContactInfo userProfile={selectedProfile} onClose={onClose} />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </Box>
  );
};
