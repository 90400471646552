import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';
import { call, put, takeLatest } from 'typed-redux-saga';

import { UserRoles, Verticals } from '@/common/interfaces';
import * as api from '@/features/settings/profile/api';
import {
  updateUserRegistrationFailed,
  updateUserRegistrationRequest,
  updateUserRegistrationSucceeded,
  userRegistrationFetchFailure,
  userRegistrationFetchRequest,
  userRegistrationFetchSuccess,
} from '@/features/settings/profile/slice';

import { userProfileFormData } from '../settings/profile/types';

interface RegistrationPayload {
  authRequestPath: string;
  data: {
    isLocationLaunched: boolean;
    isLocationAdded?: boolean;
    isBillingMethodAdded?: boolean;
    roles: UserRoles[];
    activeVertical: Verticals;
    orgId: string;
  };
}

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* handleUserRegistrationFetch(action: PayloadAction<RegistrationPayload>) {
  try {
    const result = yield* call(api.fetchUserProfile, action.payload);

    const { userRegistration } = result;
    const { skipPaymentsStep, isDefaultWorkflow } = userRegistration;
    const { authRequestPath, data } = action.payload;
    const isLocationLaunched = data.isLocationLaunched;

    // Documentation: isOnboardingComplete is considered complete if at least:
    // 1) one site added
    // 2) one credit card or one verified bank payment method added
    // 3) one site is launched
    const isOnboardingComplete =
      (userRegistration.locationsStepCompleted && userRegistration.paymentsStepCompleted && isLocationLaunched) ||
      (skipPaymentsStep && !isDefaultWorkflow && isLocationLaunched);
    const updatedRegistrationData = {
      form_data: {
        ...userRegistration,
        locationsStepCompleted: !isDefaultWorkflow || data.isLocationAdded,
        paymentsStepCompleted: skipPaymentsStep || data.isBillingMethodAdded,
        isOnboarding: !isOnboardingComplete,
      },
      org_id: action.payload.data.orgId,
    };

    if (!isEqual(updatedRegistrationData.form_data, result.userRegistration)) {
      yield* put(updateUserRegistrationRequest({ authRequestPath, data: updatedRegistrationData }));
    }
    yield* put(userRegistrationFetchSuccess(result));
  } catch (error) {
    if (error instanceof Error) {
      yield* put(userRegistrationFetchFailure(error.message));
    }
  }
}

export function* handleUserRegistrationUpdate({
  payload: { authRequestPath, data },
}: PayloadAction<{ authRequestPath: string; data: userProfileFormData }>) {
  try {
    const result = yield* call(api.updateUserProfile, { authRequestPath, data });
    if (result === 200) {
      yield* put(updateUserRegistrationSucceeded());
    } else {
      yield* put(updateUserRegistrationFailed(`error: ${result}`));
    }
  } catch (error) {
    const axiosError = error as AxiosError;

    yield* put(updateUserRegistrationFailed(axiosError.message));
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchUserRegistration() {
  yield* takeLatest(userRegistrationFetchRequest, handleUserRegistrationFetch);
}

export function* watchUpdateUserRegistration() {
  yield* takeLatest(updateUserRegistrationRequest, handleUserRegistrationUpdate);
}
