import packageJson from '../../package.json';

export const ENV = import.meta.env.VITE_TIER as string;

const PROD = 'prod';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ALPHA = 'alpha';
const DEV = 'dev';

const ENV_CONFIG_SETUP = () => {
  switch (ENV) {
    case PROD:
      return {
        TIER: import.meta.env.VITE_TIER,
        RELEASE: import.meta.env.VITE_VERSION,
        SENTRY: {
          DSN: import.meta.env.VITE_PROD_SENTRY_DSN as string,
          TRACE_RATE: Number(import.meta.env.VITE_SPROD_ENTRY_TRACE_RATE) as number,
        },
        API_URL: import.meta.env.VITE_PROD_API_URL as string,
        AUTH_0: {
          domain: import.meta.env.VITE_PROD_AUTH0_DOMAIN as string,
          clientId: import.meta.env.VITE_PROD_AUTH0_CLIENT_ID as string,
          audience: import.meta.env.VITE_PROD_AUTH0_AUDIENCE as string,
          scope: import.meta.env.VITE_PROD_AUTH0_SCOPE as string,
        },
        STRIPE: {
          key: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
        },
        PLAID: {
          env: import.meta.env.VITE_PLAID_ENV as string,
          clientName: import.meta.env.VITE_PLAID_CLIENT_NAME as string,
          publicKey: import.meta.env.VITE_PLAID_PUBLIC_KEY as string,
        },
        HJ_SITE_ID: import.meta.env.VITE_PROD_HJ_SITE_ID as number,
        HJ_VERSION: import.meta.env.VITE_PROD_HJ_VERSION as number,
        OPTIMIZELY: import.meta.env.VITE_OPTIMIZELY as string,
        UPSIDE_ORG_ID: import.meta.env.VITE_PROD_UPSIDE_ORG_ID as string,
        RECAPTCHA_SITE_KEY: import.meta.env.VITE_PROD_RECAPTCHA_SITE_KEY as string,
        APP_VERSION: packageJson.version,
      };
    case DEV:
      return {
        TIER: import.meta.env.VITE_TIER,
        RELEASE: import.meta.env.VITE_COMMIT,
        SENTRY: {
          DSN: import.meta.env.VITE_ALPHA_SENTRY_DSN as string,
          TRACE_RATE: Number(import.meta.env.VITE_ALPHA_SENTRY_TRACE_RATE) as number,
        },
        API_URL: import.meta.env.VITE_DEV_API_URL as string,
        AUTH_0: {
          domain: import.meta.env.VITE_ALPHA_AUTH0_DOMAIN as string,
          clientId: import.meta.env.VITE_ALPHA_AUTH0_CLIENT_ID as string,
          audience: import.meta.env.VITE_ALPHA_AUTH0_AUDIENCE as string,
          scope: import.meta.env.VITE_ALPHA_AUTH0_SCOPE as string,
        },
        STRIPE: {
          key: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
        },
        PLAID: {
          env: import.meta.env.VITE_PLAID_ENV as string,
          clientName: import.meta.env.VITE_PLAID_CLIENT_NAME as string,
          publicKey: import.meta.env.VITE_PLAID_PUBLIC_KEY as string,
        },
        HJ_SITE_ID: import.meta.env.VITE_ALPHA_HJ_SITE_ID as number,
        HJ_VERSION: import.meta.env.VITE_ALPHA_HJ_VERSION as number,
        OPTIMIZELY: import.meta.env.VITE_OPTIMIZELY as string,
        UPSIDE_ORG_ID: import.meta.env.VITE_ALPHA_UPSIDE_ORG_ID as string,
        RECAPTCHA_SITE_KEY: import.meta.env.VITE_ALPHA_RECAPTCHA_SITE_KEY as string,
        CI: Number(import.meta.env.CI) as number,
        PLAYWRIGHT_TEST_URL: import.meta.env.PLAYWRIGHT_TEST_URL as string,
        APP_VERSION: packageJson.version,
      };
    default:
      return {
        TIER: import.meta.env.VITE_TIER,
        RELEASE: import.meta.env.VITE_COMMIT,
        SENTRY: {
          DSN: import.meta.env.VITE_ALPHA_SENTRY_DSN as string,
          TRACE_RATE: Number(import.meta.env.VITE_ALPHA_SENTRY_TRACE_RATE) as number,
        },
        API_URL: import.meta.env.VITE_ALPHA_API_URL as string,
        AUTH_0: {
          domain: import.meta.env.VITE_ALPHA_AUTH0_DOMAIN as string,
          clientId: import.meta.env.VITE_ALPHA_AUTH0_CLIENT_ID as string,
          audience: import.meta.env.VITE_ALPHA_AUTH0_AUDIENCE as string,
          scope: import.meta.env.VITE_ALPHA_AUTH0_SCOPE as string,
        },
        STRIPE: {
          key: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
        },
        PLAID: {
          env: import.meta.env.VITE_PLAID_ENV as string,
          clientName: import.meta.env.VITE_PLAID_CLIENT_NAME as string,
          publicKey: import.meta.env.VITE_PLAID_PUBLIC_KEY as string,
        },
        HJ_SITE_ID: import.meta.env.VITE_ALPHA_HJ_SITE_ID as number,
        HJ_VERSION: import.meta.env.VITE_ALPHA_HJ_VERSION as number,
        OPTIMIZELY: import.meta.env.VITE_OPTIMIZELY as string,
        UPSIDE_ORG_ID: import.meta.env.VITE_ALPHA_UPSIDE_ORG_ID as string,
        RECAPTCHA_SITE_KEY: import.meta.env.VITE_ALPHA_RECAPTCHA_SITE_KEY as string,
        CI: Number(import.meta.env.CI) as number,
        PLAYWRIGHT_TEST_URL: import.meta.env.PLAYWRIGHT_TEST_URL as string,
        APP_VERSION: packageJson.version,
      };
  }
};

export const ENV_CONFIG = ENV_CONFIG_SETUP();
