import { useTranslation } from 'react-i18next';
import {
  FiBarChart,
  FiBarChart2,
  FiDollarSign,
  FiFile,
  FiFilePlus,
  FiFileText,
  FiGrid,
  FiHome,
  FiMapPin,
  FiPieChart,
  FiSettings,
  FiTrendingUp,
  FiUser,
  FiUsers,
} from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useToken,
} from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';

import { usePermissions } from '@/common/hooks';
import { Verticals } from '@/common/interfaces';
import { ENV_CONFIG } from '@/config';
import { Logo } from '@/features/auth/UpsideLogo';
import { selectIsDealer, selectIsParentOrg, selectIsUpsideManager, selectIsUpsider } from '@/features/auth/userSlice';
import { selectActiveVertical } from '@/features/auth/userSlice';
import { selectHasInvDataOnFirstLoad } from '@/features/reports/financialReports/invoices/slice';
import { selectPayoutsList } from '@/features/reports/financialReports/payouts/slice';
import { selectIsOnboarding, selectUserRegistration } from '@/features/settings/profile/slice';

import { selectIsDemo } from '../../slice';
import { MerchantSelector } from '../merchantSelector';

import { NavItem } from './NavItem';
import { UserProfile } from './UserProfile';

const LEFT_INDENT_PAD_SIZE = 10;

const styles = {
  settings: {
    marginLeft: '13px',
    marginRight: '15px',
  },
  logo: {
    color: 'neutral.50',
  },
};

export const SideBar = ({ hideMainNav }: { hideMainNav?: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const {
    hasPaymentsManagerAccess,
    hasPayoutManagerAccess,
    hasBillingManagerAccess,
    hasPayoutsReadOnlyAccess,
    hasStatementsInvoicesReadOnlyAccess,
    hasLocationsSettingsManagerAccess,
    hasLocationsSettingsReadOnlyAccess,
  } = usePermissions();

  const activeVertical = useAppSelector(selectActiveVertical);
  const payoutsData = useAppSelector(selectPayoutsList);
  const isUpsider = useAppSelector(selectIsUpsider);
  const isUpsideManager = useAppSelector(selectIsUpsideManager);
  const isDealer = useAppSelector(selectIsDealer);
  const isParentOrg = useAppSelector(selectIsParentOrg);
  const isDemo = useAppSelector(selectIsDemo);
  const hasInvoicesDataOnFirstLoad = useAppSelector(selectHasInvDataOnFirstLoad);
  const userRegistration = useAppSelector(selectUserRegistration);
  const isOnboarding = useAppSelector(selectIsOnboarding);

  const [green50] = useToken('colors', ['green.50']);
  const [behaviorFlag] = useDecision('md-640', { autoUpdate: true });
  const [capacityFlag] = useDecision('md-569', { autoUpdate: true });
  const [invoicesFlag] = useDecision('invoices', { autoUpdate: true });
  const [payoutsFlag] = useDecision('payouts', { autoUpdate: true });
  const [statementsFlag] = useDecision('statements', { autoUpdate: true });
  const [paymentFlag] = useDecision('md-796', { autoUpdate: true });
  const [billingFlag] = useDecision('md-809', { autoUpdate: true });
  const [profileFlag] = useDecision('md-1075', { autoUpdate: true });
  const [locationSettingsFlag] = useDecision('md-1169', { autoUpdate: true });
  const [notificationFlag] = useDecision('md-1143', { autoUpdate: true });
  const [customReportsFlag] = useDecision('md-1345', { autoUpdate: true });

  const page = t(location.pathname.split('/')[1]);
  const isSupport = page === 'Support';

  const shouldShowFinancialReports =
    (activeVertical === Verticals.Fuel || activeVertical === Verticals.Restaurant) &&
    ((!!payoutsData.rawData.length && payoutsFlag.enabled) || invoicesFlag.enabled || statementsFlag.enabled) &&
    (isUpsideManager ||
      hasBillingManagerAccess ||
      hasPaymentsManagerAccess ||
      hasPayoutManagerAccess ||
      hasPayoutsReadOnlyAccess ||
      hasStatementsInvoicesReadOnlyAccess) &&
    !isDemo;

  const shouldShowPayoutReports =
    (isUpsideManager || hasPaymentsManagerAccess || hasPayoutManagerAccess || hasPayoutsReadOnlyAccess) &&
    !!payoutsData.rawData.length &&
    payoutsFlag.enabled;

  const shouldShowNotificationOption = notificationFlag.enabled && !isOnboarding;

  const shouldShowLocationsForOnboarding = isOnboarding && userRegistration.isDefaultWorkflow;
  const shouldShowPaymentsForOnboarding = isOnboarding && !userRegistration.skipPaymentsStep;

  const shouldShowLocationsSettings =
    shouldShowLocationsForOnboarding ||
    (locationSettingsFlag.enabled &&
      (isUpsideManager || hasLocationsSettingsManagerAccess || hasLocationsSettingsReadOnlyAccess) &&
      !isDemo &&
      !isOnboarding);

  const shouldShowBillingPaymentMethods =
    shouldShowPaymentsForOnboarding ||
    (!isDealer &&
      billingFlag.enabled &&
      (isUpsideManager || hasBillingManagerAccess || hasPaymentsManagerAccess) &&
      !isDemo &&
      !isOnboarding);

  const shouldShowPayoutPaymentMethods =
    shouldShowPaymentsForOnboarding ||
    (!isDealer &&
      paymentFlag.enabled &&
      (isUpsideManager || hasPayoutManagerAccess || hasPaymentsManagerAccess) &&
      !isDemo &&
      !isOnboarding);

  const shouldShowSettingsButton =
    (isOnboarding && (shouldShowLocationsForOnboarding || shouldShowPaymentsForOnboarding)) ||
    isUpsideManager ||
    ((shouldShowPayoutPaymentMethods ||
      shouldShowBillingPaymentMethods ||
      shouldShowNotificationOption ||
      shouldShowLocationsSettings) &&
      !isParentOrg &&
      !isDemo);

  const shouldShowProfilesOption = !isOnboarding && isUpsideManager && profileFlag.enabled && !isDemo;
  const shouldShowProfileOption = !isOnboarding && !isParentOrg && profileFlag.enabled && !isDemo;

  const displayCapacity = () => {
    if (
      (ENV_CONFIG.TIER === 'prod' && activeVertical === Verticals.Fuel) ||
      (ENV_CONFIG.TIER === 'alpha' && activeVertical !== Verticals.Restaurant)
    ) {
      return (
        <NavItem
          key={'Capacity'}
          display={capacityFlag.enabled ? 'flex' : 'none'}
          path='explore/capacity'
          icon={FiTrendingUp}
          pl={LEFT_INDENT_PAD_SIZE}
        >
          {t('capacity')}
        </NavItem>
      );
    } else return;
  };

  return (
    <Flex
      flex='1'
      bg='green.1000'
      direction='column'
      borderRight='1px solid'
      borderColor='neutral.200'
      justifyContent='space-between'
      color='on-accent'
      overflowY='auto'
      maxW={'320px'}
    >
      <Stack
        justify='space-between'
        flex='1'
        spacing='1'
        w={'100%'}
        py={{ base: '6', sm: '8' }}
        px={{ base: '2', sm: '2' }}
      >
        <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren w={'100%'}>
          <Logo width='100px' ml={4} />

          {hideMainNav || isSupport ? null : (
            <Stack spacing='3' mt={5}>
              <NavItem key={'Home'} path='home' icon={FiHome} data-testid='home-button'>
                {t('home')}
              </NavItem>
              {!isOnboarding && (
                <>
                  <NavItem key={'Reports'} path='reports' icon={FiBarChart}>
                    {t('reports')}
                  </NavItem>
                  <Text fontWeight={500} color='green.50' pl={LEFT_INDENT_PAD_SIZE}>
                    {t('performanceReports')}
                  </Text>
                  <NavItem
                    key={'Profitability'}
                    path='reports/profitability'
                    icon={FiDollarSign}
                    pl={LEFT_INDENT_PAD_SIZE}
                  >
                    {t('profitability')}
                  </NavItem>
                  <NavItem key={'Customers'} path='reports/customers' icon={FiUsers} pl={LEFT_INDENT_PAD_SIZE}>
                    {t('customers')}
                  </NavItem>
                  <NavItem key={'Promotions'} path='reports/promotions' icon={FiGrid} pl={LEFT_INDENT_PAD_SIZE}>
                    {t('promotions')}
                  </NavItem>
                  {(customReportsFlag.enabled || isUpsideManager) && (
                    <NavItem key='ExportReports' path='reports/exportReports' icon={FiFile} pl={LEFT_INDENT_PAD_SIZE}>
                      {t('exportReports')}
                    </NavItem>
                  )}

                  {shouldShowFinancialReports && (
                    <>
                      <Text fontWeight={500} color='green.50' pl={LEFT_INDENT_PAD_SIZE}>
                        {t('financialReports')}
                      </Text>
                      {invoicesFlag.enabled && hasInvoicesDataOnFirstLoad && (
                        <NavItem key={'Invoices'} path='reports/invoices' icon={FiFile} pl={LEFT_INDENT_PAD_SIZE}>
                          {t('invoices')}
                        </NavItem>
                      )}
                      {statementsFlag.enabled && (
                        <NavItem
                          key={'Statements'}
                          path='reports/statements'
                          icon={FiFileText}
                          pl={LEFT_INDENT_PAD_SIZE}
                        >
                          {t('statements')}
                        </NavItem>
                      )}
                    </>
                  )}
                  {shouldShowPayoutReports && (
                    <NavItem key={'Payouts'} path='reports/payouts' icon={FiFilePlus} pl={LEFT_INDENT_PAD_SIZE}>
                      {t('payouts')}
                    </NavItem>
                  )}

                  <NavItem key={'Explore'} path='explore' icon={FiPieChart}>
                    {t('explore')}
                  </NavItem>
                  {behaviorFlag.enabled && (
                    <NavItem key='Behavior' path='explore/behavior' icon={FiBarChart2} pl={LEFT_INDENT_PAD_SIZE}>
                      {t('behavior')}
                    </NavItem>
                  )}
                  <NavItem
                    key={'Customer Deep Dive'}
                    path='explore/customerDeepDive'
                    icon={FiUser}
                    pl={LEFT_INDENT_PAD_SIZE}
                  >
                    {t('customerDeepDive')}
                  </NavItem>
                  <NavItem key={'Locations'} path='explore/locations' icon={FiMapPin} pl={LEFT_INDENT_PAD_SIZE}>
                    {t('locations')}
                  </NavItem>
                  {displayCapacity()}
                </>
              )}
            </Stack>
          )}
        </Stack>

        {/* Bottom Nav */}
        <Stack spacing={{ base: '5', sm: '6' }}>
          <Stack spacing='1'>
            {/* Merchant Selector for Upsiders */}
            {(isUpsider || isParentOrg) && <MerchantSelector />}

            {/* Settings */}
            {shouldShowSettingsButton && (
              <Menu closeOnSelect={true}>
                <MenuButton
                  as={Button}
                  textAlign='left'
                  leftIcon={<FiSettings color={green50} />}
                  style={styles.settings}
                  bg={page === 'Settings' ? 'green.700' : 'green.1000'}
                  color={'text.default-inverse'}
                  _hover={{ bg: 'green.700' }}
                  _active={{ bg: 'green.700' }}
                  textStyle='subhead.md'
                  data-testid='settings-button'
                >
                  {t('settings')}
                </MenuButton>
                <MenuList px={'5px'} borderRadius={'24px'}>
                  {(shouldShowBillingPaymentMethods || shouldShowPayoutPaymentMethods) && (
                    <MenuItem
                      onClick={() => navigate('/settings/payments')}
                      _hover={{ bg: 'green.50' }}
                      borderRadius={'30px'}
                      data-testid='payments-nav-item'
                    >
                      {t('payments')}
                    </MenuItem>
                  )}
                  {shouldShowLocationsSettings && (
                    <MenuItem
                      onClick={() => navigate('/settings/locations')}
                      _hover={{ bg: 'green.50' }}
                      borderRadius={'30px'}
                    >
                      {t('locations')}
                    </MenuItem>
                  )}
                  {shouldShowNotificationOption && (
                    <MenuItem
                      onClick={() => navigate('/settings/notification')}
                      _hover={{ bg: 'green.50' }}
                      borderRadius={'30px'}
                    >
                      {t('notification')}
                    </MenuItem>
                  )}
                  {shouldShowProfilesOption && (
                    <MenuItem onClick={() => navigate('/profiles')} _hover={{ bg: 'green.50' }} borderRadius={'30px'}>
                      {t('profiles')}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            )}
          </Stack>

          <Divider borderColor='neutral.400' />
          {/* User Options */}
          <Box>
            <Menu>
              <MenuButton cursor={'pointer'} textAlign='left' data-testid='profile-button'>
                <UserProfile />
              </MenuButton>

              <MenuList>
                {shouldShowProfileOption && <MenuItem onClick={() => navigate('/profile')}>{t('profile')}</MenuItem>}

                <MenuItem
                  onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                  data-testid='logout-button'
                >
                  Log Out
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Stack>
      </Stack>
      {isUpsider && (
        <Text my={2} textAlign='center' fontSize='xs' color='text.default-inverse'>
          {ENV_CONFIG.APP_VERSION}
        </Text>
      )}
    </Flex>
  );
};
