/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStandaloneToast } from '@chakra-ui/react';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { push } from 'redux-first-history';
import { call, put, select, takeLatest } from 'typed-redux-saga';

import { selectIsUpsider } from '@/features/auth/userSlice';
import { theme } from '@/theme';

import * as api from './api';
import * as mockApi from './mockApi';
import {
  createUserProfileFailed,
  createUserProfileRequest,
  createUserProfileSucceeded,
  passwordUpdateFailed,
  passwordUpdateRequest,
  passwordUpdateSuccess,
  updateUserProfileFailed,
  updateUserProfileRequest,
  updateUserProfileSucceeded,
  userProfileFetchFailure,
  userProfileFetchRequest,
  userProfileFetchSuccess,
  userProfilesFetchFailure,
  userProfilesFetchRequest,
  userProfilesFetchSuccess,
} from './slice';
import { ContactInfoFormData, IUpdatePasswordData, userProfileFormData } from './types';

const { toast } = createStandaloneToast({ theme });

/*****************************************************************************/
/******************************* WORKERS *************************************/
/*****************************************************************************/

export function* mockFetchUserProfile() {
  try {
    const { data } = yield* call(mockApi.mockFetchProfile);
    yield* put(userProfileFetchSuccess(data));
  } catch (error) {
    if (error instanceof Error) yield* put(userProfileFetchFailure(error.message));
  }
}

export function* fetchUserProfile(action: PayloadAction<{ authRequestPath: string }>) {
  try {
    const result = yield* call(api.fetchUserProfile, action.payload);
    console.log('result', result);
    yield* put(userProfileFetchSuccess(result));
  } catch (error) {
    if (error instanceof Error) yield* put(userProfileFetchFailure(error.message));
  }
}

export function* fetchUserProfiles(action: PayloadAction<{ authRequestPath: string }>) {
  try {
    const result = yield* call(api.fetchUserProfiles, action.payload);
    yield* put(userProfilesFetchSuccess(result));
  } catch (error) {
    if (error instanceof Error) yield* put(userProfilesFetchFailure(error.message));
  }
}

export function* createUserProfile({
  payload: { authRequestPath, data },
}: PayloadAction<{ authRequestPath: string; data: ContactInfoFormData }>) {
  try {
    const result = yield* call(api.createUserProfile, { authRequestPath, data });
    if (result === 200) {
      yield* put(createUserProfileSucceeded());
      yield* put(userProfileFetchRequest({ authRequestPath }));
      toast({
        title: 'User profile has been created',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } else {
      yield* put(createUserProfileFailed(`error: ${result}`));
    }
  } catch (error) {
    toast({
      title: 'Failed creating your user profile',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

    const axiosError = error as AxiosError;
    yield* put(createUserProfileFailed(axiosError.message));
  }
}

export function* updateUserProfile({
  payload: { authRequestPath, data },
}: PayloadAction<{ authRequestPath: string; data: userProfileFormData }>) {
  try {
    const result = yield* call(api.updateUserProfile, { authRequestPath, data });
    if (result === 200) {
      const isUpsider = yield* select(selectIsUpsider);

      yield* put(updateUserProfileSucceeded());
      if (isUpsider) {
        yield* put(userProfilesFetchRequest({ authRequestPath }));
      }

      yield* put(userProfileFetchRequest({ authRequestPath }));

      toast({
        title: 'User profile has been updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } else {
      yield* put(updateUserProfileFailed(`error: ${result}`));
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    toast({
      title: 'Failed updating your user profile',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

    yield* put(updateUserProfileFailed(axiosError.message));
  }
}

export function* updatePassword({ payload: { data } }: PayloadAction<{ data: IUpdatePasswordData }>) {
  try {
    const result = yield* call(api.updatePassword, data);
    if (result === 200) {
      yield* put(passwordUpdateSuccess());

      toast({
        title: 'Success',
        description: 'Password updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      yield* put(push('/home'));
    } else {
      yield* put(passwordUpdateFailed(`error: ${result}`));
    }
  } catch (error) {
    yield* put(passwordUpdateFailed(`${error}`));

    toast({
      title: 'New password must be different from old password.',
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchUserProfile() {
  yield* takeLatest(userProfileFetchRequest, fetchUserProfile);
}

export function* watchUserProfiles() {
  yield* takeLatest(userProfilesFetchRequest, fetchUserProfiles);
}

export function* watchUpdateUserProfile() {
  yield* takeLatest(updateUserProfileRequest, updateUserProfile);
}

export function* watchCreateUserProfile() {
  yield* takeLatest(createUserProfileRequest, createUserProfile);
}

export function* watchUpdatePassword() {
  yield* takeLatest(passwordUpdateRequest, updatePassword);
}
