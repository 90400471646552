import { Alert, AlertDescription, AlertIcon, Flex } from '@chakra-ui/react';
import { useDecision } from '@optimizely/react-sdk';
import { AnimatePresence, motion } from 'framer-motion';

export function AnnouncementBanner() {
  const [announcementBannerFlag] = useDecision('announcement_banner');

  if (!announcementBannerFlag.enabled) {
    return null;
  }

  return (
    <AnimatePresence>
      <Alert
        as={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        status='info'
        alignItems='flex-start'
        mb={10}
        bgColor='background.primary.default'
      >
        <AlertIcon color='white' />
        <Flex direction='column' color='white'>
          <AlertDescription>
            {/*
             * This component relies on the `announcement_banner` feature flag to determine whether to render.
             * - If the flag is enabled, the text content of the banner is dependent on the `variables` object.
             * - https://app.optimizely.com/v2/projects/22949391905/flags/manage/announcement_banner/variables
             * - Each variable key is expected to be in the format "message_text{number}".
             *
             * Renders a sorted list of messages from the `announcementBannerFlag.variables` object.
             * - Extracts all key-value pairs from the object (or an empty object if undefined).
             * - Sorts the entries numerically based on the suffix of keys that start with "message_text".
             *   - Non-numeric suffixes default to 0.
             * - Maps the sorted entries into <div> elements, with the message content displayed.
             * - Each <div> uses the key as its `key` prop for React's reconciliation process.
             */}
            {Object.entries(announcementBannerFlag.variables || {})
              .sort(([keyA], [keyB]) => {
                const numA = parseInt(keyA.replace('message_text', ''), 10) || 0;
                const numB = parseInt(keyB.replace('message_text', ''), 10) || 0;

                return numA - numB;
              })
              .map(([key, message]) => (
                <div key={key}>{message as string}</div>
              ))}
          </AlertDescription>
        </Flex>
      </Alert>
    </AnimatePresence>
  );
}
