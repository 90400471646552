import * as Sentry from '@sentry/react';

import { axiosInstance } from '@/common/utils';

import { ContactInfoFormData, IUpdatePasswordData, userProfileFormData } from './types';

const USER_PROFILE_BASE_URL = '/user/profile';
const USER_PROFILES_BASE_URL = '/user/profiles';

export const fetchUserProfile = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const result = await axiosInstance.post(`${USER_PROFILE_BASE_URL}/${authRequestPath}`, {});
    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const fetchUserProfiles = async ({ authRequestPath }: { authRequestPath: string }) => {
  try {
    const result = await axiosInstance.post(`${USER_PROFILES_BASE_URL}/${authRequestPath}`, {});
    return result.data;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const createUserProfile = async ({
  authRequestPath,
  data,
}: {
  authRequestPath: string;
  data: ContactInfoFormData;
}) => {
  try {
    const result = await axiosInstance.put(`${USER_PROFILE_BASE_URL}/${authRequestPath}`, { ...data });
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const updateUserProfile = async ({
  authRequestPath,
  data,
}: {
  authRequestPath: string;
  data: userProfileFormData;
}) => {
  try {
    const result = await axiosInstance.patch(`${USER_PROFILE_BASE_URL}/${authRequestPath}`, { ...data });
    return result.status;
  } catch (error) {
    Sentry.captureException(error);
    console.error(error);
    throw error;
  }
};

export const updatePassword = async ({ authRequestPath, pswrd }: IUpdatePasswordData) => {
  try {
    const result = await axiosInstance.patch(`/user/password/${authRequestPath}`, {
      form_data: {
        new_password: pswrd,
      },
    });

    return result.status;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error('Error on updating password');
  }
};
