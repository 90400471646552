import { useAuth0 } from '@auth0/auth0-react';
import { TabView } from '@components/tabView';

import { useAppSelector } from '@/app/hooks';
import { Status } from '@/common/interfaces';

import { ChangePassword } from './components/ChangePassword';
import { ContactInfo } from './components/ContactInfo';
import { selectUserProfile } from './slice';

export const Profile = () => {
  const { user } = useAuth0();

  const userProfileResponse = useAppSelector(selectUserProfile);
  const userProfile = userProfileResponse.userProfile;

  const isGoogleUser = user?.sub?.includes('google-oauth2');

  let tabViewContent = [
    {
      label: 'profilePage.tabs.contact',
      icon: undefined,
      tabPanelContent: () => <ContactInfo userProfile={userProfile} />,
    },
    {
      label: 'profilePage.tabs.password',
      icon: undefined,
      tabPanelContent: () => <ChangePassword />,
    },
  ];

  if (isGoogleUser) {
    tabViewContent = tabViewContent.slice(0, -1);
  }

  return <TabView tabViewContent={tabViewContent} loadingStatus={Status.Idle} />;
};
